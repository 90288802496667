/**
 * название функции
 *
 * @param {number} first - первое число
 * @returns {number}
 */
/*Мега меню*/
$('.btn_box a').on('click', function () {

    let href = $(this).attr('href');

    $('html, body').animate({
        scrollTop: $(href).offset().top
    }, {
        duration: 600,
        easing: "swing"
    });

    return false;
});



$(function () {

    $(".sf-menu").superfish({
            delay: 200,
            speed: "fast",
            cssArrows: false
        })
        .after("<div id='mobile-menu'>").clone().appendTo("#mobile-menu");
    $("#mobile-menu").find("*").attr("style", "");
    $("#mobile-menu").children("ul").removeClass("sf-menu")
        .parent().mmenu({
            extensions: ['widescreen', 'theme-white', 'effect-menu-slide', 'pagedim-black'],
            navbar: {
                title: "Меню"

            }
        });

    $(".toggle-mnu").click(function () {
        $(this).addClass("on");

    });

    var api = $("#mobile-menu").data("mmenu");
    api.bind("closed", function () {
        $(".toggle-mnu").removeClass("on");
    });
});


new WOW().init();
